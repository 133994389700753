const getVideoInfoVimeo = (url: $TSFixMe) => {
  if (url) {
    const matchVimeo = url.match(
      /https*:\/\/(www.|player.)*vimeo.com\/(video\/)*([0-9]{9})/,
    );
    if (matchVimeo && matchVimeo.length >= 4) {
      return { video_id: matchVimeo[3], video_type: 'vimeo' };
    }
  }
  return { video_id: null, video_type: null };
};

const getVideoInfoYoutube = (url: $TSFixMe) => {
  if (url) {
    const matchYoutube = url.match(
      /https*:\/\/(www.)*youtube.com\/(watch\?v=|embed\/)([0-9a-zA-Z-_]{11})*/,
    );
    if (matchYoutube && matchYoutube.length >= 4) {
      return { video_id: matchYoutube[3], video_type: 'youtube' };
    }
  }
  return { video_id: null, video_type: null };
};

const getVideoInfoYoutubeShortened = (url: $TSFixMe) => {
  if (url) {
    const matchYoutube = url.match(/https*:\/\/youtu.be\/([0-9a-zA-Z-_]{11})*/);
    if (matchYoutube && matchYoutube.length >= 2) {
      return { video_id: matchYoutube[1], video_type: 'youtube' };
    }
  }
  return { video_id: null, video_type: null };
};

const getVideoInfoFromUrl = (url: $TSFixMe) => {
  if (url) {
    if (url.indexOf('vimeo.com/') > -1) {
      return getVideoInfoVimeo(url);
    }
    if (url.indexOf('youtube.com/') > -1) {
      return getVideoInfoYoutube(url);
    }
    if (url.indexOf('youtu.be/') > -1) {
      return getVideoInfoYoutubeShortened(url);
    }
  }
  return { video_id: null, video_type: null };
};

const generateVideoUrl = (videoId: $TSFixMe, videoType: $TSFixMe) => {
  if (videoType === 'youtube') {
    return 'https://www.youtube.com/embed/' + videoId;
  } else if (videoType === 'vimeo') {
    return 'https://player.vimeo.com/video/' + videoId;
  }
  return '';
};

export default generateVideoUrl;
export { generateVideoUrl, getVideoInfoFromUrl };
