import React from 'react';
import useId from '@mc/hooks/useId';
import stylesheet from './MultipleChoice.css';

type Props = {
  checked: boolean;
  label?: string;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: boolean | string | number;
};

const MultipleChoiceOption = ({
  label,
  value,
  checked,
  onChange,
  question,
}: Props) => {
  const id = useId();

  return (
    <label className={stylesheet.label} htmlFor={id}>
      <input
        className={stylesheet.radioInput}
        type="radio"
        id={id}
        // @ts-expect-error TS2322
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        name={question.id}
      />
      <span className={stylesheet.labelText}>{label}</span>
    </label>
  );
};

export default MultipleChoiceOption;
