import React from 'react';
import { isFeatureEnabled } from '../../helpers/features';
import MultipleChoice from './MultipleChoice';
import Checkbox from './Checkbox';
import Text from './Text';
import Email from './Email';
import Range from './Range';
import ContactInformation from './ContactInformation';
import Dropdown from './Dropdown';
import stylesheet from './Question.css';

type Props = {
  features: $TSFixMe;
  handleEditResponse: $TSFixMeFunction;
  question: $TSFixMe;
};

const Question = ({ handleEditResponse, question, ...props }: Props) => {
  let input;
  const questionResponse = question.response;
  const dropdownQuestionEnabled = !!isFeatureEnabled(
    'add_html_select_element_feature_to_surveys',
  );
  if (
    questionResponse === 'pickOne' ||
    (questionResponse === 'dropdown' && dropdownQuestionEnabled === false)
  ) {
    input = (
      // @ts-expect-error TS2741
      <MultipleChoice
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  } else if (questionResponse === 'pickMany') {
    input = (
      // @ts-expect-error TS2741
      <Checkbox
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  } else if (questionResponse === 'text') {
    input = (
      // @ts-expect-error TS2741
      <Text
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  } else if (questionResponse === 'range') {
    input = (
      // @ts-expect-error TS2741
      <Range
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  } else if (questionResponse === 'dropdown') {
    input = (
      // @ts-expect-error TS2741
      <Dropdown
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  } else if (questionResponse === 'email') {
    input = (
      // @ts-expect-error TS2741
      <Email
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  } else if (questionResponse === 'contactInformation') {
    input = (
      // @ts-expect-error TS2741
      <ContactInformation
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  } else {
    input = (
      // @ts-expect-error TS2741
      <Text
        question={question}
        handleEditResponse={handleEditResponse}
        {...props}
      />
    );
  }

  return <div className={stylesheet.questionContainer}>{input}</div>;
};

export default Question;
