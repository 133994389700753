import React from 'react';
import classNames from 'classnames';
import SurveyTextarea from '../SurveyTextArea';
import { MAX_TEXT_LIMIT } from '../constants';
import QuestionLabel from '../QuestionLabel';
import stylesheet from './Text.css';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: string;
};

const Text = ({ handleEditResponse, question, onChange, value }: Props) => {
  return (
    <div className="question text">
      <div
        className={classNames('input-wrapper', stylesheet.textInputContainer)}
      >
        <SurveyTextarea
          label={<QuestionLabel question={question} />}
          maxLength={MAX_TEXT_LIMIT}
          onChange={onChange}
          value={value}
          shouldGrowWithContent
          minRows={3}
          handleEditResponse={handleEditResponse}
        />
      </div>
    </div>
  );
};

export default Text;
