import React from 'react';
import classNames from 'classnames';
import InputText from '../../../components/Input/InputText';
import QuestionLabel from '../QuestionLabel';
import stylesheet from './Number.css';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: {
    freeform?: string;
  };
};

const Number = ({ question, onChange, value, handleEditResponse }: Props) => {
  return (
    <div className="question text">
      <div
        className={classNames('input-wrapper', stylesheet.numberInputContainer)}
      >
        <InputText
          className={stylesheet.numberInputContainer}
          id={question.id.toString()}
          label={<QuestionLabel question={question} />}
          onChange={(result) => {
            onChange({ freeform: result });
          }}
          value={value?.freeform}
          type="number"
          // @ts-expect-error TS2322
          onBlur={() => {
            handleEditResponse();
          }}
        />
      </div>
    </div>
  );
};

export default Number;
