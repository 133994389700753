import React from 'react';
import { SURVEY_CLOSED_MESSAGE } from '../../shared/helpers/utils';
import stylesheet from './Messages.css';

type MessageProps = {
  text?: string;
};

function Message({ text }: MessageProps) {
  return (
    <div className={stylesheet.messageContainer}>
      <div
        className={stylesheet.successMessageContainer}
        // Custom HTML by user on the app. It's stripped server-side
        // eslint-disable-next-line react/no-danger
        // @ts-expect-error TS2322
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}

type CompletionMessageProps = {
  survey: $TSFixMe;
};

function CompletionMessage({ survey }: CompletionMessageProps) {
  const confirmationMessage = survey.hostedStyles.confirmationMessage
    ? survey.hostedStyles.confirmationMessage
    : 'Thank you! We appreciate your feedback.'; //TODO this will be tricky to translate because it's used in live page too. can't use hooks or tt because they rely on flag data

  return <Message text={confirmationMessage} />;
}

type ClosedMessageProps = {
  survey: $TSFixMe;
};

function ClosedMessage({ survey }: ClosedMessageProps) {
  const closedMessage = survey.hostedStyles.closedMessage
    ? survey.hostedStyles.closedMessage
    : SURVEY_CLOSED_MESSAGE; //TODO this will be tricky to translate because it's used in live page too. can't use hooks or tt because they rely on flag data
  return <Message text={closedMessage} />;
}

export { CompletionMessage, ClosedMessage };
