import React from 'react';
import styles from './SectionText.css';

type Props = {
  section: $TSFixMe;
};

function SectionText({ section }: Props) {
  return (
    <div
      className={styles.textContainer}
      data-testid="SectionTextContainer"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: section.text }}
    />
  );
}

export default SectionText;
