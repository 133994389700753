import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import useId from '@mc/hooks/useId';
import InputLabel from '../../components/Input/InputLabel';
import stylesheet from '../../components/Input/Textarea.css';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  id?: string;
  isInvalid?: boolean;
  isLabelVisible?: boolean;
  isResizable?: boolean;
  isStrictMaxLength?: boolean;
  label?: string | React.ReactNode;
  maxLength?: number;
  maxRows?: number;
  minRows?: number;
  onChange: $TSFixMeFunction;
  renderCharacterCount?: $TSFixMeFunction;
  secondaryLabel?: React.ReactNode;
  shouldGrowWithContent?: boolean;
  value?: string;
};

/**
 * A wrapper around the native browser `<textarea />`, as well as a `<label>` and other sensible defaults.
 *
 * ## Usage
 * Textarea can be used stand-alone:
 * ```js
 * import Textarea from '@mc/Input/Textarea';
 *
 * function StandaloneExample() {
 *   const [foo, setFoo] = useState('');
 *   return (
 *     <Textarea onChange={setFoo} value={foo} label="Foo" />
 *   />
 *   );
 * }
 *```
 *
 * Or as the component of a `FormField` (which must be nested inside `<Form>`), in which the required values will automatically be supplied from the `Form` context.:
 * ```js
 * import { FormField } from '@mc/components/Form';
 * import Textarea from '@mc/Input/Textarea';
 *
 * function FormFieldExample() {
 *   return (
 *     <FormField component={Textarea} />
 *   />
 *   );
 * }
 * ```
 */
const SurveyTextarea = React.forwardRef<$TSFixMe, Props>(
  function SurveyTextarea(
    {
      // @ts-expect-error TS2339
      className,
      handleEditResponse,
      id,
      isInvalid,
      isLabelVisible = true,
      isResizable = false,
      isStrictMaxLength = true,
      label: ComponentLabel,
      onChange = () => {},
      secondaryLabel,
      maxLength,
      renderCharacterCount,
      value = '',
      shouldGrowWithContent = false,
      minRows = 3,
      maxRows,
      ...props
    },
    ref,
  ) {
    const [hasFocus, setHasFocus] = useState(false);
    const [rows, setRows] = useState(minRows);
    const autoRef = useRef();
    const autoId = useId();
    const _id = id || autoId;
    const _ref = ref || autoRef;

    useEffect(() => {
      // 18 is the default line height in the app
      const rowsNeeded = Math.floor(
        (_ref as $TSFixMe).current.scrollHeight / 18,
      );
      if (shouldGrowWithContent && rowsNeeded > minRows) {
        // @ts-expect-error TS2345
        setRows(rowsNeeded > maxRows ? maxRows : rowsNeeded);
      }
    }, [_ref, maxRows, minRows, shouldGrowWithContent, value]);

    return (
      <div className={className}>
        {isLabelVisible && (
          <InputLabel htmlFor={_id} value={value} hasFocus={hasFocus}>
            {ComponentLabel}
          </InputLabel>
        )}
        {typeof ComponentLabel === 'string' && !isLabelVisible && (
          <span className="wink-visually-hidden">
            <label htmlFor={_id}>{ComponentLabel}</label>
          </span>
        )}
        {typeof ComponentLabel !== 'string' && !isLabelVisible && (
          <span className="wink-visually-hidden">
            {/* eslint-disable react/no-danger */}
            {/* @ts-expect-error TS2322 */}
            <label htmlFor={_id} dangerouslySetInnerHTML={ComponentLabel} />
          </span>
        )}
        <textarea
          id={_id}
          value={value}
          ref={_ref}
          maxLength={isStrictMaxLength ? maxLength : undefined}
          onChange={(event) => onChange(event.target.value)}
          rows={rows}
          onBlur={() => {
            setHasFocus(false);
            handleEditResponse();
          }}
          onFocus={() => setHasFocus(true)}
          {...props}
          className={classNames(stylesheet.textarea, {
            [stylesheet.invalid]: isInvalid,
            [stylesheet.resizable]: isResizable,
          })}
        />

        <div style={{ height: '1em' }}>
          <InputLabel
            htmlFor={_id}
            value={value}
            secondaryLabel={secondaryLabel}
            renderCharacterCount={renderCharacterCount}
            maxLength={maxLength}
            hasFocus={hasFocus}
          />
        </div>
      </div>
    );
  },
);

export default SurveyTextarea;
