import React from 'react';
import SingleLineText from '../SingleLineText';
import Address from './../Address';
import Dropdown from './../Dropdown';
import MultipleChoice from './../MultipleChoice';
import Number from './../Number';
import Phone from './../Phone';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: $TSFixMe;
};

const ContactInformation = ({
  handleEditResponse,
  question,
  ...props
}: Props) => {
  const mergeField = question.merge_field;
  if (mergeField) {
    switch (mergeField.type) {
      case 'dropdown':
        return (
          <Dropdown
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'text':
        return (
          <SingleLineText
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'radio':
        return (
          <MultipleChoice
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'number':
        return (
          <Number
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'phone':
        return (
          <Phone
            question={question}
            format={mergeField.options.phoneformat}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      case 'address':
        return (
          <Address
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
      default:
        return (
          <SingleLineText
            question={question}
            handleEditResponse={handleEditResponse}
            {...props}
          />
        );
    }
  } else {
    return <></>;
  }
};

export default ContactInformation;
