import React, { useState } from 'react';
import countries from '@mc/editing/blocks/runtimes/countries';
import InputText from '../../../components/Input/InputText';
import QuestionLabel from '../QuestionLabel';

import questionStylesheet from '../Question.css';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: {
    object?: {
      addr1?: string;
      addr2?: string;
      city?: string;
      country?: number;
      countryText?: string;
      state?: string;
      zip?: string;
    };
  };
};

const Address = ({ question, onChange, value, handleEditResponse }: Props) => {
  const DEFAULT_COUNTRY = 164; // USA
  const DEFAULT_COUNTRY_LABEL = 'USA';
  const MAX_CHAR_LENGTH = 255;

  const [address, setAddress] = useState({
    addr1: value?.object?.addr1 ? value.object.addr1 : '',
    addr2: value?.object?.addr2 ? value.object.addr2 : '',
    city: value?.object?.city ? value.object.city : '',
    country: value?.object?.country ? value.object.country : DEFAULT_COUNTRY,
    countryText: value?.object?.countryText
      ? value.object.country
      : DEFAULT_COUNTRY_LABEL,
    state: value?.object?.state ? value.object.state : '',
    zip: value?.object?.zip ? value.object.zip : '',
  });

  const onAddressChange = (result: $TSFixMe, addressType: $TSFixMe) => {
    const newAddressObj = { ...address };
    // @ts-expect-error TS7053
    newAddressObj[addressType] = result;
    setAddress(newAddressObj);
    onChange({ object: newAddressObj });
  };

  const onAddressChangeCountry = async (
    countryId: $TSFixMe,
    countryText: $TSFixMe,
  ) => {
    const newAddressObj = { ...address };
    newAddressObj.country = parseInt(countryId, 10);
    newAddressObj.countryText = countryText;
    setAddress(newAddressObj);
    await onChange({ object: newAddressObj });
    handleEditResponse();
  };

  // Resolve rendering issue for country label
  // Examaple: 'Turks &amp; Caicos Islands' should be 'Turks & Caicos Islands'
  const renderCountryLabel = (label: $TSFixMe) => {
    if (!label) {
      return '';
    }
    return label.replace('&amp;', '&');
  };

  return (
    <div className={questionStylesheet.question}>
      <div className={questionStylesheet.inputWrapper}>
        <InputText
          type="text"
          onChange={(result) => {
            onAddressChange(result, 'addr1');
          }}
          label={<QuestionLabel question={question} />}
          // @ts-expect-error TS2322
          placeholder="Street Address"
          value={address.addr1}
          maxLength={MAX_CHAR_LENGTH}
          renderCharacterCount={() => {}}
          onBlur={handleEditResponse}
        />
        <InputText
          type="text"
          onChange={(result) => {
            onAddressChange(result, 'addr2');
          }}
          label={<></>}
          isLabelVisible={false}
          // @ts-expect-error TS2322
          placeholder="Address Line 2"
          value={address.addr2}
          maxLength={MAX_CHAR_LENGTH}
          onBlur={handleEditResponse}
        />
        <InputText
          type="text"
          onChange={(result) => {
            onAddressChange(result, 'city');
          }}
          label={<></>}
          isLabelVisible={false}
          // @ts-expect-error TS2322
          placeholder="City"
          value={address.city}
          maxLength={MAX_CHAR_LENGTH}
          onBlur={handleEditResponse}
        />
        <InputText
          type="text"
          onChange={(result) => {
            onAddressChange(result, 'state');
          }}
          label={<></>}
          isLabelVisible={false}
          // @ts-expect-error TS2322
          placeholder="State/Province/Region"
          value={address.state}
          maxLength={MAX_CHAR_LENGTH}
          onBlur={handleEditResponse}
        />
        <InputText
          type="text"
          onChange={(result) => {
            onAddressChange(result, 'zip');
          }}
          label={<></>}
          isLabelVisible={false}
          // @ts-expect-error TS2322
          placeholder="Postal/Zip"
          value={address.zip}
          maxLength={MAX_CHAR_LENGTH}
          onBlur={handleEditResponse}
        />
        <select
          className={questionStylesheet.selectQuestion}
          onChange={(result) => {
            const label = result.target.selectedOptions[0].innerText;
            onAddressChangeCountry(result.target.value, label);
          }}
          // eslint-disable-next-line formatjs/no-literal-string-in-jsx
          // @ts-expect-error TS2322
          placeholder="Country"
          value={address.country || DEFAULT_COUNTRY}
        >
          {countries.map((country) => {
            const label = renderCountryLabel(country.label);
            return (
              <option key={label} value={country.value} aria-label={label}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default Address;
