import React from 'react';
import SurveyTextarea from '../SurveyTextArea';
import { MAX_TEXT_LIMIT } from '../constants';
import stylesheet from './Other.css';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  value?: string | number;
};

const Other = ({ handleEditResponse, onChange, value }: Props) => {
  return (
    <div className={stylesheet.wrapper}>
      <SurveyTextarea
        // @ts-expect-error TS2322
        value={value}
        onChange={onChange}
        shouldGrowWithContent
        minRows={3}
        maxLength={MAX_TEXT_LIMIT}
        handleEditResponse={handleEditResponse}
        label="Other (please specify)"
        isLabelVisible={false}
      />
    </div>
  );
};

export default Other;
