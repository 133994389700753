import React from 'react';
import useId from '@mc/hooks/useId';
import stylesheet from './Checkbox.css';

type Props = {
  checked: boolean;
  label?: string;
  onChange: $TSFixMeFunction;
  value?: boolean | string | number;
};

const CheckboxOption = ({ label, value, checked, onChange }: Props) => {
  const id = useId();

  return (
    <div className="input-wrapper">
      <label className={stylesheet.label} htmlFor={id}>
        <input
          className={stylesheet.checkboxInput}
          type="checkbox"
          id={id}
          checked={checked}
          // @ts-expect-error TS2322
          value={value}
          onChange={(event) => onChange(event.target.checked, value)}
        />
        <span className={stylesheet.labelText}>{label}</span>
      </label>
    </div>
  );
};

export default CheckboxOption;
