import React from 'react';
import classNames from 'classnames';
import QuestionLabel from '../QuestionLabel';
import InputText from '../../../components/Input/InputText';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: {
    freeform?: string;
  };
};

const SingleLineText = ({
  question,
  onChange,
  value,
  handleEditResponse,
}: Props) => {
  const MAX_CHAR_LENGTH = 255;

  return (
    <div className="question text">
      <div className={classNames('input-wrapper')}>
        <InputText
          type="text"
          label={<QuestionLabel question={question} />}
          onChange={(result) => {
            onChange({ freeform: result });
          }}
          value={value?.freeform}
          maxLength={MAX_CHAR_LENGTH}
          renderCharacterCount={() => {}}
          // @ts-expect-error TS2322
          onBlur={handleEditResponse}
        />
      </div>
    </div>
  );
};

export default SingleLineText;
