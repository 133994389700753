import React from 'react';
import Question from '../Question';
import { FormField } from '../../components';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  question: $TSFixMe;
};

function QuestionFormField({ handleEditResponse, question }: Props) {
  return question ? (
    <FormField
      name={'questions.' + question.id + '.answer'}
      label={question.inquiry}
      component={(props: $TSFixMe) => (
        <Question
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      )}
    />
  ) : null;
}

export default QuestionFormField;
