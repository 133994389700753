import React from 'react';
import InputText from '../../../components/Input/InputText';
import Checkbox from '../../../components/Input/Checkbox';
import QuestionLabel from '../QuestionLabel';
import stylesheet from './Email.css';

const DEFAULT_SUBSCRIBE_LABEL = 'Subscribe me to your newsletter';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: {
    freeform?: string;
    subscribe?: boolean;
  };
};

const Email = ({ question, onChange, value, handleEditResponse }: Props) => {
  value = value ? value : {};

  const subscribe = value.subscribe ? value.subscribe : false;
  const email = value.freeform ? value.freeform : '';

  let subscribeCheckboxLabel = question.properties.subscribeCheckboxLabel;
  if (!subscribeCheckboxLabel || !subscribeCheckboxLabel.trim()) {
    subscribeCheckboxLabel = DEFAULT_SUBSCRIBE_LABEL;
  }

  const handleEmailChange = (result: $TSFixMe) => {
    onChange({ freeform: result, subscribe: subscribe });
  };

  const handleSubscribeChange = async (checked: $TSFixMe) => {
    await onChange({ freeform: email, subscribe: checked });
    handleEditResponse();
  };

  return (
    <div className="question email">
      <div className="input-wrapper">
        <InputText
          type="email"
          value={email}
          onChange={handleEmailChange}
          label={<QuestionLabel question={question} />}
          // @ts-expect-error TS2322
          placeholder="email@example.com"
          onBlur={handleEditResponse}
        />
        {question.properties.subscribeCheckboxEnabled && (
          <div className={stylesheet.checkboxContainer}>
            <Checkbox
              value={subscribe}
              onChange={handleSubscribeChange}
              label={subscribeCheckboxLabel}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Email;
