const featuresMap = new Map();

/**
 * Stores the feature object for later use
 * @param {*} _object
 * @throws {TypeError|Error} throws errors if non object given
 */
export function registerFeatures(_object: $TSFixMe) {
  if (typeof _object === 'object') {
    Object.keys(_object).forEach((flagName) => {
      featuresMap.set(flagName, _object[flagName]);
    });
  } else {
    throw new TypeError('Wrong type of features provided');
  }
}

/**
 * Determine if a feature is active for a user
 *
 * @param {string} keyName
 *
 * @returns bool
 *
 * @throws Error when the code path looks for a feature that is not on / off this function throws an error
 * Features that are removed need to be clearly not disabled.
 */
export function isFeatureEnabled(keyName: $TSFixMe) {
  const featureState = featuresMap.get(keyName);
  if (typeof featureState !== 'undefined') {
    return featureState;
  }
  throw new Error('The feature does not exist');
}

/**
 * Cleans out the feature memo
 */
export function deRegisterFeatures() {
  for (const key of featuresMap.keys()) {
    featuresMap.delete(key);
  }
}
