import React, { useRef } from 'react';
import Form, { SubmitButton } from '../components/Form';
import stylesheet from './Form.css';
import SectionFormFieldList from './SectionFormFieldList';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onSubmit: $TSFixMeFunction;
  responseData?: $TSFixMe;
  survey: $TSFixMe;
};

const SurveyForm = ({
  handleEditResponse,
  survey,
  onSubmit,
  responseData,
}: Props) => {
  const hostedStyles = survey.hostedStyles;

  const submitBtnStyleOverrides = {};
  if (hostedStyles.buttonColor) {
    (submitBtnStyleOverrides as $TSFixMe).background = hostedStyles.buttonColor;
  }

  if (hostedStyles.buttonTextColor) {
    (submitBtnStyleOverrides as $TSFixMe).color = hostedStyles.buttonTextColor;
  }

  const submitText = hostedStyles.submitButtonText
    ? hostedStyles.submitButtonText
    : 'Submit';

  const ref = useRef();
  return (
    <div className={stylesheet.formContainer}>
      <Form
        onSubmit={() => onSubmit(ref)}
        scrollToErrors
        // @ts-expect-error TS2322
        ref={ref}
        initialValues={responseData}
      >
        <SectionFormFieldList
          survey={survey}
          handleEditResponse={() => handleEditResponse(ref)}
        />
        <div className={stylesheet.submitContainer}>
          {/*
           // @ts-expect-error TS2322 */}
          <SubmitButton type="primary" style={submitBtnStyleOverrides}>
            {submitText}
          </SubmitButton>
        </div>
      </Form>
    </div>
  );
};

export default SurveyForm;
