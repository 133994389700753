import React from 'react';
import cx from 'classnames';
import styles from './QuestionLabel.css';

type Props = {
  className?: string;
  id?: string;
  question: $TSFixMe;
};

const QuestionLabel = React.forwardRef<$TSFixMe, Props>(
  ({ className, question, id }, ref) => {
    return (
      <label htmlFor={id}>
        <div
          ref={ref}
          className={cx(
            className || '',
            styles.questionText,
            styles.questionTextHtmlContainer,
          )}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html:
              question.inquiry +
              (question.isRequired
                ? `<span data-testid="questionRequired">*</span>`
                : ''),
          }}
        />
      </label>
    );
  },
);

export default QuestionLabel;
