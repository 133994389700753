import React, { useEffect } from 'react';
import classnames from 'classnames';
import useId from '@mc/hooks/useId';
import questionStylesheet from '../Question.css';
import {
  getRangeIncrements,
  getRangeDisplayValues,
} from '../../../helpers/utils';
import QuestionLabel from '../QuestionLabel';
import stylesheet from './Range.css';

type RangeIncrementProps = {
  checked: boolean;
  disabled?: boolean;
  displayValue?: string;
  onChange: $TSFixMeFunction;
  presentation?: string;
  rangeIncrement: number;
  question: $TSFixMe;
  ariaDescribedBy?: $TSFixMe;
};

const RangeIncrement = ({
  rangeIncrement,
  displayValue,
  onChange,
  checked,
  presentation,
  disabled = false,
  question,
  ariaDescribedBy,
}: RangeIncrementProps) => {
  const id = useId();
  return (
    <>
      <input
        type="radio"
        id={id}
        value={rangeIncrement}
        className={stylesheet.surveyRadio}
        onChange={() => {
          onChange(rangeIncrement);
        }}
        checked={checked}
        disabled={disabled}
        name={question.id}
        aria-describedby={ariaDescribedBy}
      />
      <label
        className={classnames(stylesheet.surveyRadioLabel, {
          [stylesheet.disabled]: disabled,
          [stylesheet.star]: presentation === 'star',
          [stylesheet.heart]: presentation === 'heart',
          [stylesheet.smiley]: presentation === 'smiley',
        })}
        htmlFor={id}
      >
        {displayValue}
      </label>
    </>
  );
};

type RangeSetProps = {
  disabled?: boolean;
  handleEditResponse: $TSFixMeFunction;
  hideLabels?: boolean;
  highRangeLabel?: string;
  highRangeValue?: number;
  lowRangeLabel?: string;
  lowRangeValue?: number;
  onChange: $TSFixMeFunction;
  presentation?: string;
  question: $TSFixMe;
  value?: number;
};

const RangeSet = ({
  hideLabels,
  onChange,
  value,
  lowRangeLabel = '',
  highRangeLabel = '',
  lowRangeValue = 0,
  highRangeValue = 10,
  presentation = 'number',
  disabled = false,
  question,
  handleEditResponse,
}: RangeSetProps) => {
  const rangeIncrements = getRangeIncrements(lowRangeValue, highRangeValue);
  const rangeDisplayValues = getRangeDisplayValues(
    presentation,
    lowRangeValue,
    highRangeValue,
  );
  useEffect(() => {
    handleEditResponse();
  }, [handleEditResponse, value]);
  return (
    <>
      <div className={stylesheet.mobileRangeLabelContainer}>
        {lowRangeLabel && (
          <div>
            {lowRangeValue} is {lowRangeLabel}
          </div>
        )}
        {highRangeLabel && (
          <div>
            {highRangeValue} is {highRangeLabel}
          </div>
        )}
      </div>
      <fieldset className={stylesheet.rangeRadioContainer}>
        {rangeIncrements.map((rangeIncrement, index) => {
          let ariaDescribedBy = null;
          if (index === 0) {
            ariaDescribedBy = 'lowRangeLabel';
          } else if (index === rangeIncrements.length - 1) {
            ariaDescribedBy = 'highRangeLabel';
          }
          return (
            <RangeIncrement
              rangeIncrement={rangeIncrement}
              presentation={presentation}
              displayValue={rangeDisplayValues[index]}
              checked={rangeIncrement === value}
              key={rangeIncrement}
              onChange={onChange}
              disabled={disabled}
              question={question}
              ariaDescribedBy={ariaDescribedBy}
            />
          );
        })}
      </fieldset>
      {!hideLabels && (
        <div className={stylesheet.rangeLabelContainer}>
          <p className={stylesheet.rangeLabel} id="lowRangeLabel">
            {lowRangeLabel}
          </p>
          <p className={stylesheet.rangeLabel} id="highRangeLabel">
            {highRangeLabel}
          </p>
        </div>
      )}
    </>
  );
};

type RangeProps = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: number;
};

const Range = ({
  handleEditResponse,
  question,
  onChange,
  value,
}: RangeProps) => {
  return (
    <div className={questionStylesheet.question}>
      <div className={questionStylesheet.inputWrapper}>
        <QuestionLabel
          className={questionStylesheet.questionLabel}
          question={question}
        />
        <RangeSet
          handleEditResponse={handleEditResponse}
          lowRangeValue={question.properties.lowRangeValue}
          highRangeValue={question.properties.highRangeValue}
          lowRangeLabel={question.properties.lowRangeLabel}
          highRangeLabel={question.properties.highRangeLabel}
          presentation={question.properties.rangePresentation}
          onChange={onChange}
          question={question}
          value={value}
        />
      </div>
    </div>
  );
};

export { Range as default, RangeSet };
