import React from 'react';
import useId from '@mc/hooks/useId';
import QuestionLabel from '../QuestionLabel';
import questionStylesheet from '../Question.css';

type Props = {
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: {
    key?: string;
  };
};

const Dropdown = ({ question, onChange, value, handleEditResponse }: Props) => {
  const id = useId();
  return (
    <div className={questionStylesheet.questionText}>
      <div className={questionStylesheet.inputWrapper}>
        <QuestionLabel
          id={id}
          question={question}
          className={questionStylesheet.questionLabel}
        />
        <select
          className={questionStylesheet.selectQuestion}
          id={id}
          value={value ? value.key : ''}
          onChange={async (result) => {
            await onChange({ key: result.target.value });
            handleEditResponse();
          }}
        >
          <option key="" value="">
            Select...
          </option>
          {question.properties.options.map(
            (option: $TSFixMe, index: $TSFixMe) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ),
          )}
          {question.response === 'contactInformation' &&
            question.merge_field.choices.map((option: $TSFixMe) => {
              return (
                <option key={option} value={option} aria-label={option}>
                  {option}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
