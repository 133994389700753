import React from 'react';
import classNames from 'classnames';
import SingleLineText from '../SingleLineText';
import PhoneUS from './PhoneUS';

type Props = {
  format?: 'US' | 'none';
  handleEditResponse: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  question: $TSFixMe;
  value?: {
    freeform?: string;
    object?: $TSFixMe; // TODO: { area: PropTypes.number, detail1: PropTypes.number, detail2: PropTypes.number, }
  };
};

const Phone = ({
  format,
  onChange,
  question,
  value,
  handleEditResponse,
}: Props) => {
  const FORMAT_US = 'US';
  const FORMAT_INTERNATIONAL = 'none';

  return (
    <div className="question text">
      <div className={classNames('input-wrapper')}>
        {format === FORMAT_US && (
          <PhoneUS
            question={question}
            onChange={onChange}
            value={value}
            handleEditResponse={handleEditResponse}
          />
        )}
        {format === FORMAT_INTERNATIONAL && (
          <SingleLineText
            question={question}
            onChange={onChange}
            value={value}
            handleEditResponse={handleEditResponse}
          />
        )}
      </div>
    </div>
  );
};

export default Phone;
