import React from 'react';
import styles from './SectionVideo.css';
import { generateVideoUrl } from './videoUrlHelpers';

type Props = {
  invalidImageUrl?: string;
  section: $TSFixMe;
};

export const SectionVideo = ({ section, invalidImageUrl }: Props) => {
  const src = generateVideoUrl(
    section.options.video_id,
    section.options.video_type,
  );
  return (
    <div className={styles.uploadVideo}>
      {(src && (
        <iframe
          // @ts-expect-error TS2322
          allowFullScreen="on"
          title="Introduction Video"
          src={src}
          frameBorder="0"
        />
      )) ||
        (invalidImageUrl && (
          <img
            className={styles.thumbnail}
            alt="Invalid Video URL"
            src={invalidImageUrl}
          />
        )) || <span>Video URL is empty. </span>}
    </div>
  );
};

export default SectionVideo;
